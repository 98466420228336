import React from "react"
import { Helmet } from "react-helmet"
import "../../static/assets/scss/pages/page.scss"
import LayoutDark from "./shared/layout/layout-dark"
import queryString from "query-string"
import SignUpFormComponent from "./shared/components/sign-up-form.component"

const routes = require("../types/routes")

export default class CookiePolicy extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <title>Contingent: Cookie Policy</title>
          <meta name="title" content="Contingent: Cookie Policy" />
          <meta
            name="description"
            content="The intelligence platform for procurement, compliance and
                    operational resilience leaders"
          />
          <meta
            name="og:description"
            content="The intelligence platform for procurement, compliance and
                    operational resilience leaders"
          />
          <meta property="og:type" content="website" />
          <link
            rel="canonical"
            href={`${process.env.BASE_URL}/cookie-policy/`}
          />
          <meta
            property="og:url"
            content={`${process.env.BASE_URL}/cookie-policy/`}
          />
          <meta property="og:title" content="Contingent: Cookie Policy" />
          <meta
            property="og:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`${process.env.BASE_URL}/cookie-policy/`}
          />
          <meta property="twitter:title" content="Contingent: Cookie Policy" />
          <meta
            property="twitter:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
        </Helmet>
        <div className={"section-container"}>
          <div className={"section-info basic"}>
            <div className={"container contact-us"}>
              <div className={"row"}>
                <div className={"column"}>
                  <div className={"content document-sidebar"}>
                    <h2 className={"title"}>Cookie policy</h2>
                    <p className={"description"}>
                      Reference: GDPR DOC 5.0
                    </p>
                    <p className={"description"}>
                      Document Issue No: 1
                    </p>
                    <p className={"description"}>
                      Issue Date: 07 May, 2021
                    </p>
                    <p className={"description"}>
                      Review Date: 06 May, 2022
                    </p>
                  </div>
                  <div className={"document"}>
                    <h2>
                        Contingent and Future Technologies Limited Cookie and Cookie Policy
                    </h2>
                    <p>
                      Welcome to the CONTINGENT AND FUTURE TECHNOLOGIES
                      LTD's Cookie Policy.
                    </p>

                    <p>
                      This Cookie Policy (&ldquo;Policy&rdquo;) explains how
                      Contingent &amp; Future Technologies Limited (
                      &ldquo;Contingent ,&rdquo; &ldquo;we,&rdquo;
                      &ldquo;us,&rdquo; and &ldquo;our&rdquo;) use cookies and
                      similar technologies to recognise individuals who access
                      our websites and sub-domains (&ldquo;users&rdquo; or
                      &ldquo;you&rdquo;) when they visit our website and
                      interact with the content and ads that are posted on these
                      websites. This Policy outlines the types of cookies and
                      other similar technologies used on our websites, why we
                      use them, and what your rights are to control the use of
                      them. For a comprehensive understanding of the types of
                      information that we collect, how we use the information
                      that we collect, and why we collect certain types of
                      information, please read our Privacy Policy, which is
                      incorporated herein by reference. Capitalised terms not
                      defined in this Policy have the meanings set forth in our
                      Privacy Policy.
                    </p>

                    <p>
                      By continuing to visit our websites or use our services,
                      you are agreeing to the use of cookies and other similar
                      technologies for the purposes we describe in this Policy.
                    </p>

                    <h4>
                      What are Cookies?
                    </h4>
                    <p>
                      Cookies are small data files which are placed on your
                      computer or mobile device when you visit a website or use
                      an online service. For example, cookies enable us to
                      identify you and remember your preferences so we can
                      enhance your experience on our websites. They also help us
                      serve you ads relevant to your professional interests.
                    </p>

                    <h4>
                      Why Do We Use Cookies?
                    </h4>
                    <p>
                      We use cookies for a variety of reasons. These cookies may
                      be first party cookies (meaning that they are supported by
                      Contingent) or third party cookies (meaning that they are
                      hosted by a party other than Contingent such as
                      Contingent service providers and Partners). Cookies
                      can be used to recognise you when you visit our websites
                      or use our services and they allow us to bring you
                      relevant advertising both on and off our websites.
                    </p>

                    <p>
                      We use several different types of cookies on our websites,
                      including the following.
                    </p>

                    <p>
                      <u>Essential Cookies:</u> Essential cookies facilitate the
                      operation of our websites. Without the use of these
                      cookies, portions of our websites would not function
                      properly. For example, we use essential cookies to help us
                      identify which of our visitors have previously registered
                      in order to access our premium content.
                    </p>
                    <p>
                      <u>Analytical Performance Cookies</u>: Analytical
                      performance cookies help us monitor the performance of our
                      websites and how users interact with it. These cookies
                      provide us with information that allows us to improve our
                      websites and services.
                    </p>
                    <p>
                      <u>Functional Cookies: </u>Functional cookies are used to
                      remember your preferences on our websites and to
                      authenticate your identity. For example, we use functional
                      cookies to recognise and save your name and password on
                      our websites.
                    </p>
                    <p>
                      <u>Behavioural Advertising Cookies:</u> Contingent
                      and its Partners use behavioural advertising cookies in
                      order to provide you with advertising which is tailored to
                      your interests when you visit websites on and off our
                      network. Behavioral advertising cookies analyse
                      information about your interests and your browsing
                      history, and provide you with advertisements on and off
                      our network which matches these interests. Behavioral
                      advertising is based upon your web browsing activity over
                      a period of time &ndash; so it&rsquo;s different than
                      advertising you may see when you&rsquo;re looking for
                      something online using a search engine (e.g., Google,
                      Yahoo, or Bing) or on the website you may be visiting at a
                      particular time. As an example, you may be doing research
                      on products and services related to data center
                      virtualisation. If a data center virtualisation provider
                      is conducting an advertising campaign on our network, as
                      you browse the relevant portions of our sites, we may
                      serve you with one of the advertisements of one of their
                      solutions.
                    </p>

                    <p>
                      <strong>
                        III. What is Do Not Track (&ldquo;DNT&rdquo;)
                      </strong>
                    </p>
                    <p>
                      DNT is a concept that has been promoted by regulatory
                      agencies for the internet industry to enable internet
                      users to control the tracking of their online activities
                      across websites by using browser settings. The World Wide
                      Web Consortium has been working with industry groups,
                      Internet browsers, technology companies, and regulators to
                      develop a DNT technology standard. To date, no standard
                      has been adopted. As such, Contingent does not
                      generally respond to &ldquo;do not track&rdquo; signals.
                    </p>

                    <h4>
                      How are Cookies used for Advertising Purposes?
                    </h4>
                    <p>
                      Cookies and other ad technology such as beacons, pixels,
                      and tags help us to serve you ads relevant to your
                      professional interests. They also help us to provide
                      aggregated auditing, research, and reporting for
                      advertisers, understand and improve our service, and know
                      when content has been shown to you. Because your web
                      browser may request advertisements and beacons directly
                      from third party ad network servers, these networks can
                      view, edit, or set third party cookies, just as if you had
                      requested a web page from their site. Ads served by
                      Contingent may also set third party cookies.
                    </p>

                    <p>
                      If you are logged in to our services and one of our
                      cookies on your device identifies you, your usage (such as
                      your browsing behaviour) and log data (such as your IP
                      address), will be associated by us with your use of our
                      website or other technology. We also use aggregate data
                      from third parties and data from your activity on our
                      websites. Unless you clear these cookies from your
                      browser, we may use this information to:
                    </p>

                    <ul>
                      <li>
                        provide you with services, publications, and information
                        that may be of interest to you;
                      </li>
                      <li>
                        deliver or display content or interest-based advertising
                        that matches your professional interests and
                        responsibilities;
                      </li>
                      <li>
                        offer you opportunities to connect directly with your
                        peers and with providers of technologies, goods, and
                        services that may be of interest to you; or
                      </li>
                      <li>
                        detect and defend against fraud and other risks to
                        protect our members and partners.
                      </li>
                    </ul>


                    <h4>
                      How Can You Control the Use of Cookies?
                    </h4>
                    <p>
                      You have the power to control the use of cookies on your
                      devices. You may modify your browser settings to accept or
                      reject all cookies, or to prevent it from accepting
                      certain cookies or certain types of cookies. It may
                      be possible to set your browser to not accept cookies and
                      ask for your consent before each cookie is set on your
                      device. If you modify your browser settings to prevent it
                      from accepting all cookies, please be aware that the
                      functionality of many of our websites will be impaired.
                    </p>
                    <p>
                      For further information on cookies generally, including
                      how to control and manage them, visit the{" "}
                      <a href="http://ico.org.uk/for-the-public/online/cookies/">
                        guidance on cookies
                      </a>{" "}
                      published by the UK Information Commissioner&rsquo;s
                      Office,{" "}
                      <a href="http://www.aboutcookies.org/">
                        www.aboutcookies.org
                      </a>{" "}
                      or{" "}
                      <a href="http://www.allaboutcookies.org/">
                        www.allaboutcookies.org
                      </a>
                      .
                    </p>

                    <h4>
                          How Can I Prevent Cookies or Other Tracking
                          Technologies and Uses of My Information?
                    </h4>
                    <p>
                      You can view and manage the cookies we set on this website
                      via our consent manager here. You will continue to be
                      served with advertisements; however, they will not be
                      tailored to your web preferences, usage patterns and
                      professional interests. If you do not want to receive
                      cookies, you can also change your browser settings on your
                      computer or other device. If you use our websites and
                      services without changing your browser settings, then we
                      will assume that you have agreed to receive all cookies on
                      our websites.
                    </p>
                    <p>
                      We will ask for your consent to place cookies or other
                      similar technologies on your device, except where they are
                      essential for us to provide you with a service that you
                      have requested (eg. to enable you to receive our
                      professional emails you have requested).
                    </p>
                    <p>
                      You can withdraw any consent to the use of cookies or
                      manage any other cookies preferences in line with
                      section V. How Can You Control the Use of Cookies?
                      and our Privacy Notice. It may be necessary to refresh the
                      page for the updated settings to take effect.
                    </p>
                    <p>
                      <u>Privacy Policy</u>
                      <strong>:</strong> For additional information about how we
                      collect and use your information and how you may exercise
                      your rights, please read our Privacy Policy.
                    </p>

                    <p>
                      <strong>VII. Helpful Resources</strong>
                    </p>
                    <p>
                      Additional information about cookies can be found on the
                      following websites:
                    </p>
                    <p>
                      <a href="http://www.aboutcookies.org/">
                        www.aboutcookies.org
                      </a>
                      ; or
                    </p>
                    <p>

                      <a href="http://www.allaboutcookies.org/">
                        www.allaboutcookies.org
                      </a>
                      .
                    </p>

                    <p>
                      <strong>VIII. Changes to this Policy</strong>
                    </p>
                    <p>
                      We may update this Policy from time to time to reflect
                      changes, for example to the cookies that we use or for
                      other legal, operational or legitimate business
                      purposes. If we make any material changes in the way
                      in which we use cookies, we will provide you with
                      appropriate notice.
                    </p>

                    <h4>
                      Questions
                    </h4>
                    <p>
                      If you have any questions about the use of cookies on our
                      websites or about this Policy in general, please email{" "}
                      <a href="mailto:GDPR@contingent.ai">GDPR@contingent.ai</a>{" "}
                      or by post Contingent and Future Technologies Limited 9th
                      Floor, 107 Cheapside, London, United Kingdom, EC2V 6DN
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Move towards proactive third-party risk management and
                    monitoring today.
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}
